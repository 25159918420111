import { useMutation } from '@apollo/client'
import StudentLink from 'components/Students/StudentLink'
import { EUserTypes } from 'components/User/userTypes'
import { UPDATE_USER_REGISTRATION } from 'graphql/UPDATE_USER_REGISTRATION'
import { useRootStore } from 'hooks'
import { calcTotalPaid } from 'modules/payment/utils/calcTotalPaid'
import { calcTotalFees } from 'modules/user/registrations/utils/calcTotalFees'
import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Avatar, Button, Dropdown, Table, Tag, Text } from 'tabler-react'
import useReactRouter from 'use-react-router'
import { formatMoney } from 'utils/numberFormat'
import { Types } from '../../types/graphql'

interface IEventRegistrationListItemProps {
  student: Types.RegisteredStudents
}

const EventRegistrationListItem = ({
  student
}: IEventRegistrationListItemProps) => {
  const { history } = useReactRouter()
  const { currentUser } = useRootStore()
  const [isEdittingRating, setIsEdittingRating] = useState<boolean>(false)
  const [rating, setRating] = useState<number>(student.rating ?? 0)
  const [updateUserRegistration] = useMutation(UPDATE_USER_REGISTRATION, {
    refetchQueries: ['GET_USER_REGISTRATIONS_BY_IDS', 'GET_REGISTERED_STUDENTS']
  })

  const remainingBalance = useMemo(() => {
    const balance =
      calcTotalFees(student) -
      calcTotalPaid(
        student.payments.map(({ amount, status }) => ({
          amount,
          status
        }))
      )
    return balance > 0 ? balance : 0
  }, [student])

  const compTotal = useMemo(() => {
    return student.payments.reduce((acc, payment) => {
      if (payment.status === 'Comp') {
        return acc + payment.amount
      }
      return acc
    }, 0)
  }, [student.payments])

  const totalFees = useMemo(() => {
    return calcTotalFees(student)
  }, [student])

  const paymentStatus = useMemo(() => {
    if (compTotal === totalFees) {
      return 'Comp'
    }

    if (remainingBalance === 0) {
      return 'Paid'
    }
    return ''
  }, [compTotal, remainingBalance, totalFees])

  let statusIcon = 'bg-danger'
  if (student.uspa.uspaStatus === 'USPA Active') {
    statusIcon = 'bg-success'
  }

  if (student.uspa.isLicenseExpiring) {
    statusIcon = 'bg-warning'
  }

  const updateRating = async () => {
    updateUserRegistration({
      variables: {
        userRegistration: {
          id: student.user_registrations_id,
          rating: rating,
          coach_id: currentUser.id
        }
      }
    })
    setIsEdittingRating(false)
  }

  return (
    <>
      {currentUser.type === EUserTypes.admin && (
        <Table.Col>
          <Text RootComponent="span" muted>
            <Link
              to={`/events/registrations/${student.user_registrations_id}/${student.id}`}
            >
              {student.user_registrations_id}
            </Link>
          </Text>
        </Table.Col>
      )}
      <Table.Col>
        <Avatar
          size="md"
          className="cursor-pointer"
          status={student.status_color ?? 'danger'}
          imageURL={
            student?.profile_avatar
              ? `/api/s3/uploads/${student.profile_avatar}`
              : '//www.gravatar.com/avatar?d=mp'
          }
          onClick={() =>
            history.push(
              `/events/registrations/${student.user_registrations_id}/${student.id}`
            )
          }
        />
      </Table.Col>
      <Table.Col>
        <strong>
          <StudentLink
            studentId={Number(student.id)}
            studentName={`${student.first_name} ${student.last_name}`}
          />
          <Text 
            className="cursor-pointer d-block"
            onClick={() =>
              history.push(
                `/events/registrations/${student.user_registrations_id}/${student.id}`
              )
            }
          >
          {student.first_name + ' ' + student.last_name}
        </Text>
        </strong>
        <Text.Small className="text-muted d-block">
          <span>
            {student.city}
            {student.province && ', ' + student.province}
          </span>
        </Text.Small>
      </Table.Col>
      <Table.Col>
        <Link
          to={`/events/registrations/${student.user_registrations_id}/${student.id}`}
        >
          <Button
            className="mr-3"
            icon={student.status_icon ?? 'x-circle'}
            size="sm"
            color={student.status_color ?? 'danger'}
          >
            {student.status}
          </Button>
        </Link>
        {student.status === 'Confirmed' && (
          <>
            {isEdittingRating ? (
              <input
                autoFocus
                min={0}
                max={10}
                type="number"
                value={rating}
                onChange={(e) => {
                  const value = Number(e.target.value)
                  if (value > 10) setRating(10)
                  if (value < 0) setRating(0)
                  if (value >= 0 && value <= 10) setRating(value)
                }}
                onBlur={async () => {
                  updateRating()
                }}
                onKeyUp={(event) => {
                  switch (event.keyCode) {
                    case 13:
                      updateRating()
                      break
                    case 27:
                      setIsEdittingRating(false)
                      break
                  }
                }}
              />
            ) : (
              <Button
                outline
                icon="star"
                size={'sm'}
                color={rating > 0 ? 'info' : 'secondary'}
                //  className={rating > 0 ? 'text-warning' : ''}
                onClick={() => setIsEdittingRating(true)}
              >
                {rating > 0 && <span className="ml-1">{rating}</span>}
              </Button>
            )}
          </>
        )}
      </Table.Col>
      <Table.Col>
        {student.user_registration_options.length > 0 &&
          student.user_registration_options.map((option) => {
            return (
              <span className={'d-block'} key={option.id}>
                <Tag color="white" className="text-muted">
                  {option.name}{' '}
                  {option.option_value && '$' + option.option_value}
                </Tag>
                <Tag className="ml-2">{option.quantity ?? 1}</Tag>
              </span>
            )
          })}
      </Table.Col>
      <Table.Col>
        {compTotal !== 0 && student.status === 'Confirmed' && (
          <Button outline color="secondary" size="sm" className="mr-2 mb-1">
            Comp: {formatMoney(compTotal)}
          </Button>
        )}
        {remainingBalance !== 0 &&
          student.status === 'Confirmed' &&
          compTotal !== totalFees && (
            <>
              <Button
                color={
                  totalFees - remainingBalance !== 0 ? 'secondary' : 'danger'
                }
                size="sm"
                className="mr-2 mb-1 d-block"
              >
                Paid: {formatMoney(totalFees - remainingBalance)}/
                {formatMoney(totalFees - compTotal)}
              </Button>
              <Button
              //  icon="alert-circle"
                color={'gray-dark'}
                size="sm" className="mr-2"
                disabled
                >
                  Owes: {formatMoney(remainingBalance)}
                </Button>
            </>
          )}
        {remainingBalance === 0 && totalFees !== 0 && (
          <Button
            icon={paymentStatus === "Paid" ? 'check-circle' : ''}
            color={paymentStatus === 'Paid' ? 'success' : 'secondary'}
            size="sm"
            //  className={paymentStatus === 'Comp' ? 'text-danger' : ''}
          >
            {paymentStatus} {formatMoney(totalFees)}
          </Button>
        )}
      </Table.Col>
      <Table.Col>
        <Tag>
          {student.uspa.uspaStatus === 'USPA Unknown' ? (
            <span>USPA Unknown</span>
          ) : (
            <div>
              <span className={`status-icon ${statusIcon}`} />
              {student.uspa.uspaStatus}
            </div>
          )}
        </Tag>
      </Table.Col>
      <Table.Col>
        <Button.Dropdown value="Actions" color="secondary" size="sm">
          <Dropdown.Item>
            <a href={'mailto:' + student.email} className="text-muted">
              <i className="fe fe-mail text-muted m-2" />
              Email
            </a>
          </Dropdown.Item>
          <Dropdown.Item>
            <a
              href={
                '/events/registrations/' +
                student.user_registrations_id +
                '/' +
                student.id
              }
              className="text-muted"
            >
              <i className="fe fe-user text-muted m-2" />
              View Registration
            </a>
          </Dropdown.Item>
        </Button.Dropdown>
      </Table.Col>
    </>
  )
}

export default EventRegistrationListItem
