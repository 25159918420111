import { useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import React, { useMemo } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { Button, Form, Grid } from "tabler-react";
import Modal from "../../../components/Modal";
import { ADD_STUDENT_TO_TEAM } from "../../../graphql/ADD_STUDENT_TO_TEAM";
import { GET_STUDENT_TEAM } from "../../../graphql/GET_STUDENT_TEAM";
import { GET_STUDENT_TEAM_LIST } from "../../../graphql/GET_STUDENT_TEAM_LIST";

const AddStudentTeam = ({ userId, isOpen, toggle }) => {
  const { data, loading } = useQuery(GET_STUDENT_TEAM_LIST, {
    variables: { student_id: userId },
  });
  const [addStudentToTeam] = useMutation(ADD_STUDENT_TO_TEAM);

  const options = useMemo(() => {
    if (loading || !data) return [];
    return data.getTeamListByStudentId
      .filter((team) => !team.is_active)
      .map((team) => ({
        value: team.id,
        label: team.name,
        is_active: team.is_active,
      }))
      .filter(
        (team, index, self) =>
          index === self.findIndex((t) => t.value === team.value),
      );
  }, [data, loading]);

  return (
    <Modal
      title="Add Student Team"
      open={isOpen}
      onClose={toggle}
      content={
        <Formik
          enableReinitialize={true}
          initialValues={{ team_id: "", student_id: userId }}
          onSubmit={async (values) => {
            await addStudentToTeam({
              variables: {
                student_id: Number(values.student_id),
                team_id: Number(values.team_id),
              },
              refetchQueries: [
                "GET_SPORTS_LOCATIONS_TEAMS_PROGRESSIONS_BY_STUDENT_ID",
                "GET_STUDENT_TEAM",
                "GET_STUDENT_TEAM_LIST",
              ],
            });
            toast.success("Student added to team");
            toggle();
          }}
        >
          {({ values, setFieldValue, handleSubmit }) => {
            return (
              <Form>
                <Grid.Row>
                  <Grid.Col>
                    <Form.Group label="Co Hosting Team">
                      <Select
                        name="team_id"
                        options={options}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        menuPortalTarget={document.body}
                        onChange={(e) => setFieldValue("team_id", e.value)}
                        value={options.filter(
                          (team) => team.value === values.team_id,
                        )}
                      />
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col>
                    <Form.Group>
                      <Button
                        color="grey-dark"
                        className="float-right"
                        size="sm"
                        pill
                        onClick={handleSubmit}
                      >
                        SUBMIT
                      </Button>
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>
              </Form>
            );
          }}
        </Formik>
      }
    />
  );
};

export default AddStudentTeam;
