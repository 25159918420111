import { useMutation, useQuery } from "@apollo/client";
import { IconBrandWhatsapp } from "@tabler/icons-react";
import CalendarEventSidebar from "components/Calendar/CalendarEventSidebar";
import CalendarEventEdit from "components/Calendar/Forms/CalendarEventEdit";
import CalendarItemEditRegistration from "components/Calendar/Forms/CalendarItemEditRegistration";
import { Loading } from "components/Loading";
import StudentRegistrationForm from "components/Students/Forms/StudentRegistrationForm";
import UserPostForm from "components/Students/Forms/UserPostForm";
import UserPostsList from "components/User/UserPostsList";
import { EUserTypes } from "components/User/userTypes";
import * as DOMPurify from "dompurify";
import { Formik } from "formik";
import { DELETE_CAMP } from "graphql/DELETE_CAMP";
import { GET_CAMP_BY_ID } from "graphql/GET_CAMP_BY_ID";
import { GET_CAMP_REGISTRATION_OPTIONS } from "graphql/GET_CAMP_REGISTRATION_OPTIONS";
import { GET_CAMPS } from "graphql/GET_CAMPS";
import { GET_USER_CAMP_REGISTRATION } from "graphql/GET_USER_CAMP_REGISTRATION";
import { GET_CAMP_BY_ID as GET_CAMP_BY_ID_TYPE } from "graphql/types/GET_CAMP_BY_ID";
import { GET_CAMP_REGISTRATION_OPTIONS as GET_CAMP_REGISTRATION_OPTIONS_TYPE } from "graphql/types/GET_CAMP_REGISTRATION_OPTIONS";
import { GET_USER_CAMP_REGISTRATION as GET_USER_CAMP_REGISTRATION_TYPE } from "graphql/types/GET_USER_CAMP_REGISTRATION";
import { UPDATE_CAMP_BY_ID } from "graphql/UPDATE_CAMP_BY_ID";
import { useRootStore } from "hooks";
import { observer } from "mobx-react";
import FeatureAccessGate from "modules/common/components/FeatureAccessGate";
import UploadImageDropdown from "modules/common/components/UploadImageDropdown";
import {
  useGetUserIsAdmin,
  useGetUserIsStudent,
  useRoles,
} from "modules/common/hooks/useGetCurrentUserType";
import EventOrganizersButton from "modules/user/roles/components/buttons/EventOrganizersButton";
import EventOrganizerList from "modules/user/roles/components/list/EventOrganizerList";
import useGetEventOrganizers from "modules/user/roles/hooks/useGetEventOrganizers";
import moment from "moment-timezone";
import React, { useEffect, useMemo, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import Moment from "react-moment";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, Dropdown, Grid, Icon, Tag } from "tabler-react";
import useReactRouter from "use-react-router";
import { GET_CAMP_COHOSTS } from "../../graphql/GET_CAMP_COHOSTS";
import useGetCurrentUserType from "../../modules/common/hooks/useGetCurrentUserType";
import PageNoAccess from "../../pages/PageNoAccess";
import { Types } from "../../types/graphql";
import FormField from "../FormField";

const EditWhatsAppGroup = ({ campId, whatsAppGroup, updateCampById }) => {
  const [isEditing, setIsEditing] = useState(false);
  const isAdmin = useGetUserIsAdmin();

  return (
    <div className={"d-inline-flex mr-2"}>
      {isEditing ? (
        <Formik
          enableReinitialize={true}
          initialValues={{ whatsAppGroup: whatsAppGroup, id: campId }}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            await updateCampById({
              variables: {
                camp: {
                  id: campId,
                  whatsapp_group: values.whatsAppGroup,
                },
              },
              refetchQueries: ["GET_CAMP_BY_ID"],
            });
            toast.success("WhatsApp Group updated");

            setSubmitting(false);
            resetForm();
            setIsEditing(false);
          }}
        >
          {({ values, handleChange, handleSubmit, setFieldValue }) => (
            <FormField
              type="text"
              name="whatsAppGroup"
              placeholder="WhatsApp Group"
              onChange={handleChange}
              value={values.whatsAppGroup}
              onBlur={handleSubmit}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSubmit();
                }
              }}
              appendright={
                <Button
                  size={"sm"}
                  color={"danger"}
                  onClick={(e) => {
                    e.preventDefault();
                    setFieldValue("whatsAppGroup", "");
                    handleSubmit();
                  }}
                >
                  REMOVE
                </Button>
              }
            />
          )}
        </Formik>
      ) : (
        <Button
          outline
          color="secondary"
          size="sm"
          disabled={!isAdmin}
          onClick={() => setIsEditing(true)}
        >
          <IconBrandWhatsapp size={14} className="mr-1" />
          WhatsApp Group
        </Button>
      )}
    </div>
  );
};

const EditWhatsApp = ({ campId, whatsApp, updateCampById }) => {
  const [isEditing, setIsEditing] = useState(false);
  const isAdmin = useGetUserIsAdmin();

  return (
    <div className={"d-inline-flex mr-2"}>
      {isEditing ? (
        <Formik
          enableReinitialize={true}
          initialValues={{ whatsApp: whatsApp, id: campId }}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            await updateCampById({
              variables: {
                camp: {
                  id: campId,
                  camp_whatsapp: values.whatsApp,
                },
              },
              refetchQueries: ["GET_CAMP_BY_ID"],
            });
            toast.success("Camp WhatsApp updated");
            setSubmitting(false);
            resetForm();
            setIsEditing(false);
          }}
        >
          {({ values, handleChange, handleSubmit, setFieldValue }) => (
            <FormField
              type="text"
              name="whatsApp"
              placeholder="WhatsApp"
              onChange={handleChange}
              value={values.whatsApp}
              onBlur={handleSubmit}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSubmit();
                }
              }}
              appendright={
                <Button
                  size={"sm"}
                  color={"danger"}
                  onClick={(e) => {
                    console.log(e);
                    e.preventDefault();
                    if (e.key === "Enter") {
                      return;
                    }
                    setFieldValue("whatsApp", "");
                    handleSubmit();
                  }}
                >
                  REMOVE
                </Button>
              }
            />
          )}
        </Formik>
      ) : (
        <Button
          outline
          color="secondary"
          size="sm"
          disabled={!isAdmin}
          onClick={() => setIsEditing(true)}
        >
          <IconBrandWhatsapp size={14} className="mr-1" />
          WhatsApp
        </Button>
      )}
    </div>
  );
};

const CalendarEventView = () => {
  const rootStore = useRootStore();
  const { currentUser } = rootStore;
  const { eventId } = useParams<{ eventId: string }>();
  const isStudent = useGetUserIsStudent();

  const {
    loading: loading1,
    error,
    data,
  } = useQuery<GET_CAMP_BY_ID_TYPE>(GET_CAMP_BY_ID, {
    variables: {
      campId: Number(eventId),
    },
  });

  const { loading: loading2, data: registrationData } =
    useQuery<GET_USER_CAMP_REGISTRATION_TYPE>(GET_USER_CAMP_REGISTRATION, {
      variables: {
        studentId: Number(currentUser?.id),
        campId: Number(eventId),
      },
    });

  const { loading: loading3, roles, isTeamAdmin } = useRoles();

  if (error) {
    console.log(error);

    return <p>Error: {error.message}</p>;
  }

  const campRegistration = registrationData?.getUserCampRegistration;

  if (loading1 || loading2 || loading3) {
    return <Loading />;
  }

  const studentId = isStudent ? currentUser?.id : null;
  const event = data.getCampById;
  const teamId = Number(event?.team_id);

  return (
    <CalendarEventViewInner
      event={data.getCampById}
      campRegistration={campRegistration}
      eventId={Number(data.getCampById.id)}
      studentId={studentId}
      teamId={teamId}
      isTeamAdmin={isTeamAdmin}
    />
  );
};

const CalendarEventViewInner = ({
  event,
  campRegistration,
  eventId,
  studentId,
  teamId,
  isTeamAdmin,
}) => {
  const campId = eventId;
  const { history } = useReactRouter();
  const rootStore = useRootStore();
  const { isAdmin, isCoachSuperAdmin, isEventOrganizer, isStudent } =
    useGetCurrentUserType();
  const isCoachAdmin = isTeamAdmin;
  const { currentUser } = rootStore;
  const { currentCoachTeam } = useRootStore();

  const [view, setView] = useState("view");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
  const [registrationOptions, setRegistrationOptions] =
    useState<Types.CampRegistrationOption[]>();

  const handleSetIsModalOpen = (isOpen: boolean) => {
    setIsModalOpen(isOpen);
  };

  const toggleIsDuplicateModalOpen = () => {
    setIsDuplicateModalOpen(!isDuplicateModalOpen);
  };

  const { eventOrganizersData } = useGetEventOrganizers({
    eventId,
  });

  const isCoachEventOrganizer = eventOrganizersData?.getEventOrganizers.some(
    (organizer) => organizer.coach_id === currentUser?.id,
  );

  const { data: regOpsData, loading: regOptionLoading } =
    useQuery<GET_CAMP_REGISTRATION_OPTIONS_TYPE>(
      GET_CAMP_REGISTRATION_OPTIONS,
      {
        variables: {
          campId,
          teamId,
        },
      },
    );

  const { loading: loadingTeams, data: dataTeams } = useQuery(
    GET_CAMP_COHOSTS,
    {
      variables: {
        campId: Number(event.id),
      },
      fetchPolicy: "network-only",
    },
  );

  const coHostingTeams = useMemo(() => {
    if (loadingTeams || !dataTeams?.getCampCoHosts) return [];
    return dataTeams?.getCampCoHosts.filter((team) => team.is_active);
  }, [dataTeams, loadingTeams]);

  useEffect(() => {
    if (!regOptionLoading && regOpsData?.getAvailableCampRegistrationOptions) {
      const options = regOpsData?.getAvailableCampRegistrationOptions.filter(
        (option) => option.is_exist,
      );
      setRegistrationOptions(options);
    }
  }, [regOpsData, regOptionLoading]);

  const isRegistrationStatusConfirmed =
    campRegistration?.status === "Confirmed";
  const filter = { team_id: undefined };

  if (
    currentUser?.type !== EUserTypes.admin &&
    currentUser?.type !== EUserTypes.student
  ) {
    filter.team_id = currentCoachTeam?.id;
  }

  const [updateCampById] = useMutation(UPDATE_CAMP_BY_ID, {
    refetchQueries: ["GET_CAMP_BY_ID", "GET_CAMPS"],
  });

  const [deleteCamp] = useMutation(DELETE_CAMP, {
    onCompleted: (result) => {
      if (result.deleteCamp) {
        toast.success("Camp deleted");
        history.push("/events");
      }
    },
  });

  const updatePublicHandle = async (is_public) => {
    await updateCampById({
      variables: {
        camp: {
          id: eventId,
          is_public: is_public ? 1 : 0,
        },
      },
    });
  };

  const updatePublishedHandle = async (is_published) => {
    await updateCampById({
      variables: {
        camp: {
          id: eventId,
          is_published: is_published ? 1 : 0,
        },
      },
    });
  };

  const confirmDelete = () => {
    if (
      window.confirm(
        "Are you sure you want to DELETE this event? Deleted events can not be recovered.",
      )
    ) {
      deleteCamp({
        variables: {
          campId,
        },
        refetchQueries: [
          {
            query: GET_CAMPS,
            variables: { filter: { ...filter, isDateAgnostic: true } },
          },
        ],
      });
    }
  };

  const onError = (error) => {
    toast.error(error);
    rootStore.setLoading(false);
  };

  const onFinished = (response: { fileKey: string }) => {
    updateCampImage(response.fileKey);
    rootStore.setLoading(false);
  };

  const updateCampImage = async (fileKey: string | null) => {
    await updateCampById({
      variables: {
        camp: {
          id: eventId,
          camp_image: fileKey,
        },
      },
    });
  };

  if (view === "edit") {
    return <CalendarEventEdit event={event} setIsEditing={setView} />;
  }

  if (view === "registration") {
    return (
      <CalendarItemEditRegistration event={event} setIsEditing={setView} />
    );
  }

  if (
    !isStudent &&
    !isAdmin &&
    Number(event.team_id) !== currentCoachTeam?.id &&
    !isCoachEventOrganizer
  ) {
    return <PageNoAccess />;
  }

  return (
    <>
      {isModalOpen && (
        <StudentRegistrationForm
          camp={event}
          toggleModal={setIsModalOpen}
          isModalOpen={isModalOpen}
          defaultJumpCount={event.default_jump_count ?? 1}
          studentIdProp={studentId}
        />
      )}

      <Grid.Row className="mb-5">
        <Grid.Col
          lg={2}
          ignoreCol={true}
          className="timer-container d-none d-lg-block"
        >
          <div className="w-100">
            <time className="cal-icon">
              <em></em>
              <strong>
                <Moment format="MMMM">{event.start}</Moment>
              </strong>
              <span>
                <Moment format="DD">{event.start}</Moment>
              </span>
            </time>
          </div>
        </Grid.Col>
        <Grid.Col lg={8} ignoreCol={true}>
          <Card className="card-profile">
            <Card.Header
              backgroundURL={
                event.camp_image
                  ? `/api/s3/uploads/${event.camp_image}`
                  : "//via.placeholder.com/800x300.png?text=+"
              }
              className="camp-image"
            >
              {!isStudent && (
                <div className="banner-dropdown-container">
                  <Dropdown
                    className="cursor-pointer banner-dropdown"
                    position="bottom-end"
                    icon="camera"
                    items={
                      <UploadImageDropdown
                        imageName={event.camp_image}
                        onErrorHandler={onError}
                        onFinishedHandler={onFinished}
                        callBack={updateCampImage}
                      />
                    }
                    toggle={false}
                  />
                </div>
              )}
            </Card.Header>
          </Card>
        </Grid.Col>
        <Grid.Col lg={2}></Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col lg={12} sm={12} xs="auto">
          <strong style={{ color: "#fd9f1b" }}>
            <Moment format="MMM D, YYYY">{event.start}</Moment> -{" "}
            <Moment format="MMM D, YYYY">{event.end}</Moment>
          </strong>
          <h2 className="mt-1 mb-1">{event.camp_name}</h2>
          <p
            onClick={() => history.push(`/locations/${event.location_id}`)}
            className="cursor-pointer text-gray-dark"
          >
            {event.location}
          </p>
          <Tag color="danger">
            {moment(event.end).isBefore() ? "This event has ended" : ""}
          </Tag>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row className="is-flex justify-content-end mb-5">
        <Grid.Col lg={6} sm={12} xs="auto">
          <Button.List align="right">
            {event.registration_type === "slots" && !isStudent && (
              <Button
                icon="calendar"
                color="secondary"
                RootComponent="a"
                href={`/team/events/slot-calendar/${eventId}`}
                size="sm"
              >
                {" "}
                SLOTS
              </Button>
            )}
            <EditWhatsApp
              whatsApp={event.camp_whatsapp}
              campId={campId}
              updateCampById={updateCampById}
            />
            <EditWhatsAppGroup
              campId={campId}
              whatsAppGroup={event.whatsapp_group}
              updateCampById={updateCampById}
            />

            {event.camp_email && (
              <Button
                icon="mail"
                color="info"
                RootComponent="a"
                href={`mailto:${event.camp_email}`}
                size="sm"
              ></Button>
            )}
            {isAdmin ||
              ((isCoachEventOrganizer || currentCoachTeam) &&
                !event.camp_email && (
                  <Button
                    outline
                    icon="plus"
                    color="secondary"
                    size="sm"
                    onClick={() => setView("edit")}
                  >
                    Email
                  </Button>
                ))}
            {event.camp_phone && (
              <Button
                icon="phone"
                color="info"
                size="sm"
                RootComponent="a"
                href={`tel:+${event.camp_phone_country_code}${event.camp_phone}`}
              ></Button>
            )}
            {isAdmin ||
              ((isCoachEventOrganizer || currentCoachTeam) &&
                !event.camp_phone && (
                  <Button
                    outline
                    icon="plus"
                    color="secondary"
                    size="sm"
                    onClick={() => setView("edit")}
                  >
                    Phone
                  </Button>
                ))}
            {event.camp_url && (
              <Button
                color="info"
                href={`//${event.camp_url}`}
                icon="link"
                RootComponent="a"
                size="sm"
                target="_blank"
              ></Button>
            )}
            {isAdmin ||
              ((isCoachEventOrganizer || currentCoachTeam) && !event.camp_url && (
                <Button
                  outline
                  icon="plus"
                  color="secondary"
                  size="sm"
                  onClick={() => setView("edit")}
                >
                  Website
                </Button>
              ))}
            {(isAdmin || currentCoachTeam || isCoachEventOrganizer) && (
              <Dropdown
                className="btn btn-white btn-sm pl-0 pr-0"
                toggle={false}
                icon="more-vertical"
                isNavLink={true}
                position="bottom-end"
                arrow={true}
                arrowPosition="right"
                items={
                  <>
                    {(isAdmin ||
                      isCoachSuperAdmin ||
                      moment().diff(moment(event.end), "days") < 7) && (
                      <Dropdown.Item onClick={() => setView("edit")}>
                        <Icon name="edit" /> Edit
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item
                      onClick={() => setIsDuplicateModalOpen(true)}
                    >
                      <Icon name="copy" /> Duplicate
                    </Dropdown.Item>
                    <FeatureAccessGate feature="feature_registrations">
                      {event.status === "Registrations" &&
                        (isAdmin ||
                          isCoachSuperAdmin ||
                          moment().diff(moment(event.end), "days") < 7) && (
                          <Dropdown.Item
                            onClick={() => setView("registration")}
                          >
                            <Icon name="settings" /> Settings
                          </Dropdown.Item>
                        )}
                    </FeatureAccessGate>
                    <Dropdown.Item
                      className="text-muted"
                      onClick={confirmDelete}
                    >
                      <Icon name="x-circle" /> DELETE
                    </Dropdown.Item>
                  </>
                }
              />
            )}
          </Button.List>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col lg={8} sm={12} xs={12}>
          <Card className="textEditor-display">
            <Card.Header>
              <Card.Title>Details</Card.Title>
              <Card.Options>
                {!isStudent && (
                  <Button
                    size={"sm"}
                    color={"white"}
                    className={
                      event.is_published ? "text-success" : "text-muted"
                    }
                    onClick={() => updatePublishedHandle(!event.is_published)}
                  >
                    <Icon
                      name={event.is_published ? "check-circle" : "lock"}
                      className={
                        event.is_published
                          ? "text-success mr-2"
                          : "text-muted mr-2"
                      }
                    />
                    <span>
                      {event.is_published ? "Published" : "Unpublished"}
                    </span>
                  </Button>
                )}
                {!isStudent && (
                  <>
                    <Button
                      size={"sm"}
                      color={"white"}
                      className={event.is_public ? "text-info" : "text-muted"}
                      onClick={() => updatePublicHandle(!event.is_public)}
                      //  disabled={isStudent}
                    >
                      <Icon
                        name={event.is_public ? "calendar" : "users"}
                        className="mt-1 mr-2"
                      />
                      <span>{event.is_public ? "Public" : "Private"}</span>
                    </Button>
                    {(isAdmin || (isEventOrganizer && isCoachAdmin)) && (
                      <EventOrganizersButton />
                    )}
                  </>
                )}
              </Card.Options>
            </Card.Header>
            <Card.Body>
              <Grid.Row className="mb-3">
                <Grid.Col>
                  <p className="mb-0">
                    <Icon name="calendar" className="mr-2" />
                    <Moment format="MMM D, YYYY">{event.start}</Moment> -{" "}
                    <Moment format="MMM D, YYYY">{event.end}</Moment>
                  </p>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row className="mb-3">
                <Grid.Col>
                  <p className="mb-0">
                    <Icon name="clock" className="mr-2" />
                    <Moment format="LT">{event.start}</Moment> -{" "}
                    <Moment format="LT">{event.end}</Moment>{" "}
                  </p>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row className="mb-3">
                <Grid.Col>
                  <p className="d-inline">
                    <Icon name="users" className="mr-2" />
                    <span>
                      Hosted by{" "}
                      {event.team_slug ? (
                        <Link
                          className="text-gray-dark"
                          to={`/${event.team_slug}`}
                        >
                          <strong>{event.team_name}</strong>
                        </Link>
                      ) : (
                        <strong>{event.team_name}</strong>
                      )}
                    </span>
                    {coHostingTeams.length > 0 &&
                      coHostingTeams.map((team: Types.CampHostTeam) => (
                        <>
                          ,{" "}
                          <span key={`${team.name}`}>
                            {team.slug ? (
                              <Link
                                className="text-gray-dark"
                                to={`/${team.slug}`}
                              >
                                <strong>{team.name}</strong>
                              </Link>
                            ) : (
                              <strong>{team.name}</strong>
                            )}
                          </span>
                        </>
                      ))}
                  </p>
                </Grid.Col>
              </Grid.Row>

              <Grid.Row className="mb-3">
                <Grid.Col>
                  <p
                    onClick={() =>
                      history.push(`/locations/${event.location_id}`)
                    }
                    className="cursor-pointer text-gray-dark"
                  >
                    <Icon className="mr-2" name="map-pin" />
                    <strong>{event.location}</strong>
                  </p>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row className="mb-3 ws-prewrap">
                <Grid.Col>
                  {ReactHtmlParser(DOMPurify.sanitize(event.camp_description))}
                </Grid.Col>
              </Grid.Row>
              <Grid.Row className="mb-3">
                <Grid.Col>
                  <Tag>{event.event_type_name}</Tag>
                  <Tag className="ml-2">{event.sport_type_name}</Tag>
                </Grid.Col>
              </Grid.Row>
            </Card.Body>
          </Card>

          <EventOrganizerList />

          {(!isStudent || isRegistrationStatusConfirmed) && (
            <>
              {moment().diff(moment(event.end), "days") < 7 && (
                <Card.Body className="p-0 mb-4">
                  <UserPostForm />
                </Card.Body>
              )}

              <UserPostsList campId={campId} />
            </>
          )}
        </Grid.Col>

        <CalendarEventSidebar
          event={event}
          isDuplicateModalOpen={isDuplicateModalOpen}
          isRegistrationStatusConfirmed={isRegistrationStatusConfirmed}
          registrationOptions={registrationOptions}
          handleSetIsModalOpen={handleSetIsModalOpen}
          toggleIsDuplicateModalOpen={toggleIsDuplicateModalOpen}
        />
      </Grid.Row>
    </>
  );
};
export default observer(CalendarEventView);
